import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function PaginationBar({
    nPages = 1,
    currentPage,
    pageSize,
    base,
    setCurrentPage,
    onPageSizeChange,
    forBulk = false,
    handleBulkPageButton
}) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    let temp1Pages = 5;
    const visiblePages = 1;
    if (nPages > 5) {
        temp1Pages = currentPage + 2;
    }
    if (temp1Pages > nPages) {
        temp1Pages = nPages;
    }

    const pageNumbers = [];
    let tempStart = 1;
    if (currentPage > 3 && nPages > 5) {
        tempStart = currentPage - 2;
    }
    const start =
        nPages > 5 ? Math.max(currentPage - visiblePages, tempStart) : 1;
    const end =
        nPages > 5 ? Math.min(+currentPage + visiblePages, temp1Pages) : nPages;

    if (nPages > 2 && nPages == currentPage) {
        const start = nPages > 5 ? currentPage - 2 : 1;
        for (let i = start; i <= end; i++) {
            pageNumbers.push(i);
        }
    } else {
        for (let i = start; i <= end; i++) {
            pageNumbers.push(i);
        }
    }

    if (nPages > 5) {
        if (+currentPage + visiblePages < nPages - 1) {
            pageNumbers.push('...');
            pageNumbers.push(nPages);
        }
    }

    const goToNextPage = () => {
        if (forBulk) {
            navigate(base + '?bulkpage=' +( Number(currentPage + '') + 1));
            handleBulkPageButton(Number(currentPage)+1);
        } else {
            if (currentPage !== nPages)
                if (setCurrentPage) {
                    setCurrentPage(currentPage + 1);
                } else {
                    navigate(base + '?page=' + (Number(currentPage + '') + 1));
                }
        }
    };



    const goToPrevPage = () => {
        if (forBulk) {
            navigate(base + '?bulkpage=' + (Number(currentPage + '') - 1));
            handleBulkPageButton(Number(currentPage) - 1);
        } else {
            if (currentPage !== 1)
                if (setCurrentPage) {
                    setCurrentPage(currentPage - 1);
                } else {
                    navigate(base + '?page=' + (Number(currentPage + '') - 1));
                }
        }
    };

    const lastPage = nPages;
    return (
        <>
            {
                <div className='dataTables_wrapper'>
                    <div className='row align-items-center'>
                        <div className='col-sm-12 col-md-5'>
                            <div className='dataTables_info'>
                                {t('Common.Showing')}{' '}
                                <select
                                    className='paginate_button'
                                    value={pageSize}
                                    onChange={e => {
                                        onPageSizeChange(e.target.value);
                                    }}
                                >
                                    <option value='10'>10</option>
                                    <option value='25'>25</option>
                                    <option value='50'>50</option>
                                    <option value='100'>100</option>
                                </select>{' '}
                                {t('Common.entries')}
                            </div>
                        </div>
                        {nPages > 1 ? (
                            <div className='col-sm-12 col-md-7 d-flex justify-content-end'>
                                <div className='dataTables_paginate'>
                                    <nav aria-label='Standard pagination example'>
                                        <ul className='pagination'>
                                            <li className='paginate_button page-item previous'>
                                                <button
                                                    className={
                                                        currentPage == 1
                                                            ? 'page-link disabled'
                                                            : 'page-link'
                                                    }
                                                    aria-label='Previous'
                                                    onClick={goToPrevPage}
                                                    disabled={currentPage == 1}
                                                >
                                                    <span aria-hidden='true'>
                                                        {t('Common.Prev')}
                                                    </span>
                                                </button>
                                            </li>
                                            {pageNumbers.map(pgNumber => (
                                                <li
                                                    key={pgNumber}
                                                    className={`paginate_button page-item ${
                                                        currentPage == pgNumber
                                                            ? 'active'
                                                            : ''
                                                    } `}
                                                >
                                                    <a
                                                        onClick={() => {
                                                            if (
                                                                pgNumber !=
                                                                '...'
                                                            ) {
                                                                if (
                                                                    setCurrentPage
                                                                ) {
                                                                    setCurrentPage(
                                                                        pgNumber
                                                                    );
                                                                } else {
                                                                   {
                                                                    if(forBulk){
                                                                        navigate(
                                                                            base +
                                                                                '?bulkpage=' +
                                                                                pgNumber
                                                                        );
                                                                        handleBulkPageButton(pgNumber)
                                                                    } else {
                                                                        navigate(
                                                                            base +
                                                                                '?page=' +
                                                                                pgNumber
                                                                        );
                                                                    }
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                        className='page-link'
                                                    >
                                                        {pgNumber}
                                                    </a>
                                                </li>
                                            ))}
                                            <li className='paginate_button page-item next'>
                                                <button
                                                    className={
                                                        currentPage == lastPage
                                                            ? 'page-link disabled'
                                                            : 'page-link'
                                                    }
                                                    aria-label='Next'
                                                    onClick={goToNextPage}
                                                    disabled={
                                                        currentPage == lastPage
                                                    }
                                                >
                                                    <span aria-hidden='true'>
                                                        {t('Common.Next')}
                                                    </span>
                                                </button>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            }
        </>
    );
}

export default PaginationBar;
