import React, { useState } from "react";
import ApexChart from 'react-apexcharts';

const VerticalBarGraph = ({ data, categories, xLabel,yLabel }) => {

    const options = {
        chart: {
            type: 'bar',
            height: 150,
            toolbar: {
                show: false,
            },
        },
        colors: '#126E36',
        plotOptions: {
            bar: {

                horizontal: false,
                columnWidth: '30%',
                dataLabels: {
                    position: 'top',
                },
            },
        },
        dataLabels: {
            enabled: false,
            offsetX: -6,
            style: {
                fontSize: '12px',
            },
        },
        stroke: {
            show: true,
            width: 0,
        },
        tooltip: {
            enabled: true,
        },
        xaxis: {
            categories: categories,
            title: {
                text: xLabel,
                style: {
                    fontFamily: 'Inter, sans-serif',
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: '13px',
                    color: '#828282',
                },
            },
        },
        yaxis: {
            title: {
                text: yLabel,
                style: {
                    fontFamily: 'Inter, sans-serif',
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: '13px',
                    color: '#828282',
                },
            },
        },
    }
    return (
        <ApexChart
            options={options}
            series={data}
            type='bar'
            height='360'
        />
    );
};

export default VerticalBarGraph;
