import React, { useState } from "react";
import { Form, FormCheck } from "react-bootstrap";
import Select, { components } from "react-select";


const InputOption = ({ getStyles, Icon, isDisabled, isFocused, isSelected, children, innerProps, ...rest }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";
    const style = {
        alignItems: "center",
        backgroundColor: bg,
        color: "inherit",
        display: "flex "
    };
    const props = { ...innerProps, onMouseDown, onMouseUp, onMouseLeave, style };
    return (
        <components.Option
            {...rest}
            isDisabled={isDisabled}
            isFocused={isFocused}
            isSelected={isSelected}
            getStyles={getStyles}
            innerProps={props}
        >
            <Form.Check className="form-check-inline m-0">
                <FormCheck.Input type="checkbox" className="form-check-input check_select" checked={isSelected} />
            </Form.Check>
            {children}
        </components.Option>
    );
};

const MultiSelectSBU = ({ allOptions, selectedAgents, handleChange }) => {
    return (
        <div className="select2_custom checkbox_custom">
            <Select
                value={selectedAgents}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={handleChange}
                placeholder="Select Agent"
                maxMenuHeight={200}
                options={allOptions}
                components={{ Option: InputOption }}
            />

        </div>
    );
};

export default MultiSelectSBU;
