import { Button, Nav, Tab, Modal } from 'react-bootstrap';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import * as LoginSelector from '../../../../store/auth/selectors';
import { client } from '../../../../services/apiService';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import {
    imagePath,
    successNotify,
    throttleFunction,
} from '../../../../CommonLogic';
import {
    RC_ACCEPT,
    RC_ACCEPTED,
    RC_ACTION_ACCEPT,
    RC_ACTION_ACCEPTED,
    RC_ACTION_ALL,
    RC_ACTION_COMMENT,
    RC_ACTION_COMMENTS,
    RC_ACTION_EDITED,
    RC_ACTION_HISTORY,
    RC_ACTION_REBUTTAL,
    RC_ACTION_REBUTTAL_REJECTED,
    RC_ACTION_REBUTTAL_SMALL_CASE,
    RC_ACTION_REJECT,
    RC_AGENT,
    RC_AUDIT_DONE,
    RC_AUDIT_EDITED,
    RC_AUDIT_REVIEWED,
    RC_COMMENT,
    RC_COMMENTS,
    RC_EDIT,
    RC_HISTORY,
    RC_NO_COMMENT,
    RC_REBUTTAL,
    RC_REBUTTAL_REJECTED,
    RC_REJECT,
} from '../../../../Constant';
import { useTranslation } from 'react-i18next';

export default function RebuttalComments({
    allComments,
    title,
    currentStatus,
    getComments,
    reportId,
    mode,
    auditStatusView,
    Report_Data,
    showFor,
    setIsParameterEditable,
    isParameterEditable = false,
}) {
    const [everyComment, setEveryComment] = useState([]);
    const [hasRebuttal, setHasRebuttal] = useState(false);
    const [rebuttalComment, setRebuttalComments] = useState('');

    const navigate = useNavigate();
    useMemo(() => {
        const temp = _.orderBy(allComments, ['id'], ['asc']);
        setEveryComment(temp);
        temp.map(row => {
            if (row.Audit_Status == RC_REBUTTAL) {
                setHasRebuttal(true);
            }
        });
    }, [allComments]);

    const [showModal, setShowModal] = useState(false);
    const userInfo = useSelector(LoginSelector.loggedInUser);
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);
    const [show3, setShow3] = useState(false);
    const submitFunctionSingleCall = useRef(false);
    const handleClose3 = () => {
        setShow3(false);
        submitFunctionSingleCall.current = false;
    };
    const handleShow3 = () => setShow3(true);
    const stages = {
        'Audit Done': 'soft_gray',
        Rebuttal: 'soft_red',
        'Audit Edited': 'soft_blue',
        'Audit Reviewed': 'soft_blue',
        Accepted: 'soft_green',
    };
    const [currentTab, setCurrentTab] = useState('All');

    const [confirmTitle, setConfirmTitle] = useState('');
    const [accepted, setAccepted] = useState(false);
    const handleRebuttalSingleCall = useRef(false);
    const { t } = useTranslation();

    const getTimeline = commentId => {
        return (
            <ul>
                {getData(currentTab).map(stage => {
                    return stage.id <= commentId
                        ? getStageCard(stage.Audit_Status)
                        : null;
                })}
            </ul>
        );
    };
    const getStageCard = stage => {
        if (stage == RC_REBUTTAL_REJECTED) stage = RC_AUDIT_REVIEWED;
        return stage ? (
            <li>
                <button className={'btn ' + stages[stage]}>{stage}</button>
            </li>
        ) : null;
    };

    const getNegativeAction = (considerAccept = false) => {
        return considerAccept && accepted
            ? RC_ACCEPT
            : currentStatus == RC_AUDIT_DONE || currentStatus == RC_AUDIT_EDITED
              ? RC_REBUTTAL
              : RC_REJECT;
    };

    const getData = tabName => {
        switch (tabName) {
            case RC_ACTION_ALL:
                return everyComment;
            case RC_ACTION_COMMENTS:
                return everyComment.filter(
                    row => row.Comment && row.Comment !== RC_NO_COMMENT && true
                );
            case RC_ACTION_HISTORY:
                return everyComment;
        }
    };

    const getTimelineMessage = row => {
        return (
            <>
                <strong className='name'>
                    {row.Updated_By
                        ? row.Updated_By.first_name
                            ? row.Updated_By.first_name +
                              ' ' +
                              (row.Updated_By.last_name || '').trim()
                            : row.Updated_By.email.replace(/\./gi, ' ')
                        : 'System'}
                </strong>{' '}
                {t('Perform Audit.has')} {primaryAction(row.Type)}{' '}
                {currentTab != RC_HISTORY &&
                row.Comment &&
                row.Comment !== RC_NO_COMMENT &&
                row.Type !== RC_COMMENT ? (
                    <>
                        {t('Perform Audit.and added')} <strong>{t('Perform Audit.Comment')}</strong>
                    </>
                ) : (
                    ''
                )}
            </>
        );
    };
    const primaryAction = type => {
        switch (type) {
            case RC_ACTION_COMMENT:
                return (
                    <>
                        <strong>{t('Perform Audit.added')}</strong> {t('Perform Audit.a comment')}
                    </>
                );
            case RC_ACTION_EDITED:
                return (
                    <>
                        <strong>{t('Perform Audit.edited')}</strong> {t('Perform Audit.the audit feedback')}
                    </>
                );
            case RC_ACTION_REBUTTAL_SMALL_CASE:
                return (
                    <>
                        <strong>{t('Perform Audit.rebutted')}</strong> {t('Perform Audit.the audit feedback')}
                    </>
                );
            case RC_ACTION_ACCEPTED:
                return (
                    <>
                        <strong>{t('Perform Audit.accepted')}</strong> {t('Perform Audit.the audit feedback')}
                    </>
                );
            case RC_ACTION_REBUTTAL_REJECTED:
                return (
                    <>
                        <strong>{t('Perform Audit.rejected rebuttal')}</strong> {t('Perform Audit.the audit feedback')}
                    </>
                );
            default:
                return (
                    <>
                        <strong>{t('Perform Audit.submitted')}</strong> {t('Perform Audit.the audit feedback')}
                    </>
                );
        }
    };

    function checkAgentStatus(userInfo, comments) {
        if (userInfo.role == RC_AGENT) {
            return comments?.some(item => {
                const auditStatus = item.Audit_Status;
                return (
                    auditStatus === RC_REBUTTAL || auditStatus === RC_ACCEPTED
                );
            });
        }
        return false;
    }

    const CommentsBody = () => {
        const [comment, setComment] = useState('');
        return (
            <div className='rebutal_tab_body'>
                <div className='rebutal_comment_list'>
                    <>
                        {getData(currentTab).map(row => {
                            const acceptedText = row.Comment;
                            const modifiedText = acceptedText.replace(
                                /\(System\)/,
                                ''
                            );
                            const parts = modifiedText.split(
                                ' has accepted the audit'
                            );
                            const firstText = parts[0].trim();
                            const secondText = t('Perform Audit.has accepted the audit');
                            return (
                                !(
                                    currentTab == RC_HISTORY &&
                                    row.Type == RC_COMMENT
                                ) && (
                                    <div className='rebutal_comment_views'>
                                        <div className='media_img'>
                                            <img
                                                src={imagePath(
                                                    '/images/user_agent.png'
                                                )}
                                            />
                                        </div>
                                        <div className='media_body'>
                                            <div className='media_name'>
                                                <h4>
                                                    {getTimelineMessage(row)}
                                                </h4>
                                            </div>
                                            <div className='date_time'>
                                                <h5>
                                                    {moment(
                                                        row.createdAt
                                                    ).format(
                                                        'MMM Do, YYYY [at] hh:mm A'
                                                    )}
                                                </h5>
                                            </div>
                                            {currentTab != RC_COMMENTS &&
                                            row.Type != RC_COMMENT ? (
                                                <div className='rebutal_track'>
                                                    {getTimeline(row.id)}
                                                </div>
                                            ) : null}
                                            {row.Audit_Status ==
                                                RC_REBUTTAL_REJECTED && (
                                                <div className='marking'>
                                                    {t('Perform Audit.Rebuttal has been rejected by the QA')}
                                                </div>
                                            )}
                                            {row.Comment &&
                                            row.Comment !== RC_NO_COMMENT ? (
                                                <div className='rebutal_msg'>
                                                    <p>
                                                        {currentTab !=
                                                            RC_HISTORY &&
                                                        row.Comment ===
                                                            'Workspace QMS (System) has accepted the audit' ? (
                                                            <div className='autoAcceptedMessage'>
                                                                <IoIosInformationCircleOutline
                                                                    size={22}
                                                                />{' '}
                                                                <strong>
                                                                    {firstText}
                                                                </strong>{' '}
                                                                {secondText}
                                                            </div>
                                                        ) : (
                                                            <span>
                                                                {' '}
                                                                {
                                                                    row.Comment
                                                                }{' '}
                                                            </span>
                                                        )}
                                                    </p>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                )
                            );
                        })}

                      {auditStatusView != 'qaView' &&   (<>
                            {currentTab != RC_COMMENTS &&
                                !checkAgentStatus(userInfo, allComments) &&
                                ([RC_AUDIT_DONE].includes(currentStatus) ||
                                    mode == RC_EDIT ||
                                    (mode != RC_EDIT && !hasRebuttal)) && (
                                    <div className='rebutal_comment_views'>
                                        <div className='media_body'>
                                            <div className='permission_button_box '>
                                                <button
                                                    className='btn soft_green'
                                                    onClick={e => {
                                                        setAccepted(true);
                                                        setConfirmTitle(
                                                            mode == 'View'
                                                                ? t('Perform Audit.Are you sure you want to accept this audit?')
                                                                : showFor ==
                                                                    RC_AGENT &&
                                                                    mode == RC_EDIT
                                                                    ? t('Perform Audit.Are you sure you want to accept the rebuttal request?')
                                                                    : t('Perform Audit.Are you sure you want to submit the audit?')
                                                        );
                                                        handleShow3();
                                                    }}
                                                    disabled={isParameterEditable}
                                                >
                                                    {t('Common.Accept')}
                                                </button>
                                                <button
                                                    className='btn soft_red ms-3'
                                                    onClick={e => {
                                                        setAccepted(false);
                                                        if (
                                                            getNegativeAction() ==
                                                            RC_REBUTTAL
                                                        ) {
                                                            // REBUTTAL
                                                            setConfirmTitle(
                                                                t('Perform Audit.Are you sure you want to Rebuttal this audit?')
                                                            );
                                                        } else {
                                                            setConfirmTitle(
                                                                t('Perform Audit.Are you sure you want to reject the rebuttal request?')
                                                            );
                                                        }
                                                        handleShow3();
                                                    }}
                                                    disabled={isParameterEditable}
                                                >
                                                    {getNegativeAction()}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}

                            <textarea
                                value={comment}
                                onChange={e => setComment(e.target.value)}
                                className='form-control'
                                placeholder={`${t('Perform Audit.Add Comments')}...`}
                            ></textarea>

                            <div className='bottom-actions'>
                                <button
                                    className='btn comman_btn'
                                    onClick={async () => {
                                        setComment('');
                                        await client.post(
                                            `/audit-reports/update-report/${reportId}`,
                                            {
                                                comment,
                                            }
                                        );
                                        getComments(true);
                                    }}
                                    disabled={comment?.trim() ? false : true}
                                >
                                    {t('Common.Send')}
                                </button>
                            </div>
                        </>
                        )}
                    </>
                </div>
            </div>
        );
    };

    const handleRebuttal = async () => {
        if (handleRebuttalSingleCall.current) {
            return;
        }
        handleRebuttalSingleCall.current = true;
        handleCloseModal();
        await client.post(`/audit-reports/update-report/${reportId}`, {
            comment:
                document.getElementById('commentsB4Submission')?.value || '',
            status: RC_REBUTTAL,
        });
        successNotify(t('Perform Audit.Rebutted Audit successfully'));
        navigate(-1);
    };

    const handleModalSubmit = async action => {
        if (submitFunctionSingleCall.current) {
            return;
        }
        submitFunctionSingleCall.current = true;
        handleClose3();
        let message = '';
        switch (action) {
            case RC_ACTION_REBUTTAL:
                setShowModal(true);
                return;
            case RC_ACTION_REJECT:
                await client.post(`/audit-reports/update-report/${reportId}`, {
                    comment: '',
                    status: RC_REBUTTAL_REJECTED,
                });
                message = t('Perform Audit.Rebuttal Rejected successfully');
                break;

            case RC_ACTION_ACCEPT:
                if (showFor == RC_AGENT && mode == RC_EDIT) {
                    const button = document.querySelector(
                        '.savebuttonrebuttal'
                    );
                    if (button) {
                        button.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                        });
                    }
                    setIsParameterEditable(true);
                    break;
                }
                await client.post(`/audit-reports/update-report/${reportId}`, {
                    status: mode == RC_EDIT ? RC_AUDIT_EDITED : RC_ACCEPTED,
                    Report_Data,
                });
                message = t('Perform Audit.Audit updated successfully');
                break;
        }
        if (
            !(showFor == RC_AGENT && mode == RC_EDIT) ||
            (showFor == RC_AGENT && mode == RC_EDIT && action == RC_REJECT)
        ) {
            successNotify(message);
            navigate(-1);
        }
    };

    return (
        <>
            <div className='rebuttal_vidget vidget_comman'>
                <h2 className='font-weight-500'>{title}</h2>
                <div className='vidget_comman_inner tab_view'>
                    <div className='bd-example'>
                        <Tab.Container defaultActiveKey='first'>
                            <Nav
                                variant='pills'
                                data-toggle='slider-tab'
                                role='tablist'
                            >
                                <Nav.Item role='presentation'>
                                    <Nav.Link
                                        eventKey='first'
                                        variant='  d-flex align-items-center'
                                        data-bs-toggle='tab'
                                        data-bs-target='#rebuttal_all'
                                        type='button'
                                        role='tab'
                                        aria-controls='all'
                                        aria-selected='true'
                                        onClick={e => setCurrentTab('All')}
                                    >
                                        {t('Common.All')}
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item role='presentation'>
                                    <Nav.Link
                                        eventKey='second'
                                        variant=' d-flex align-items-center'
                                        data-bs-toggle='tab'
                                        data-bs-target='#rebuttal_comments'
                                        type='button'
                                        role='tab'
                                        aria-controls='comments'
                                        aria-selected='false'
                                        onClick={e =>
                                            setCurrentTab(RC_COMMENTS)
                                        }
                                    >
                                        {t('Perform Audit.Comments')}
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item role='presentation'>
                                    <Nav.Link
                                        eventKey='third'
                                        id='contact-tab'
                                        data-bs-toggle='tab'
                                        data-bs-target='#rebuttal_history'
                                        type='button'
                                        role='tab'
                                        aria-controls='history'
                                        aria-selected='false'
                                        onClick={e => setCurrentTab(RC_HISTORY)}
                                    >
                                        {t('Perform Audit.History')}
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <hr></hr>
                            <Tab.Content>
                                <Tab.Pane
                                    eventKey='first'
                                    variant=' fade show active'
                                    id='rebuttal_all'
                                    role='tabpanel'
                                    aria-labelledby='rebuttal_all'
                                >
                                    <CommentsBody
                                        key={'commentBody_' + reportId}
                                    />
                                </Tab.Pane>
                                <Tab.Pane
                                    eventKey='second'
                                    variant=' fade show active'
                                    id='rebuttal_comments'
                                    role='tabpanel'
                                    aria-labelledby='rebuttal_comments'
                                >
                                    <CommentsBody
                                        key={'commentBody2_' + reportId}
                                    />
                                </Tab.Pane>
                                <Tab.Pane
                                    eventKey='third'
                                    variant=' fade show active'
                                    id='rebuttal_history'
                                    role='tabpanel'
                                    aria-labelledby='rebuttal_history'
                                >
                                    <CommentsBody
                                        key={'commentBody3_' + reportId}
                                    />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </div>
            {/* Modal for confirm */}
            <Modal
                show={show3}
                onHide={handleClose3}
                backdrop='static'
                keyboard={false}
                className='alret_modal'
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className='alret_modal_body'>
                        <div className='text-center'>
                            <h4>{confirmTitle}</h4>
                        </div>
                        <div className='alret_modal_bottom'>
                            <Button
                                variant='link'
                                className='bordered_btn'
                                onClick={handleClose3}
                            >
                                {t('Common.No')}
                            </Button>
                            <Button
                                variant='primary'
                                className='ms-3'
                                onClick={() =>
                                    handleModalSubmit(getNegativeAction(true))
                                }
                            >
                                {t('Common.Yes')}, {getNegativeAction(true)}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* Modal for Rebuttal comments */}
            <Modal
                show={showModal}
                onHide={handleCloseModal}
                backdrop='static'
                keyboard={false}
                className='alret_modal'
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className='alret_modal_body'>
                        <div className='text-center'>
                            <h4>{t('Perform Audit.Add Comment before rebuttal')}</h4>
                        </div>
                        <div>
                            <textarea
                                placeholder={`${t('Perform Audit.Add Comments')}.....`}
                                style={{ height: '64px' }}
                                className='form-control'
                                id='commentsB4Submission'
                                onChange={e =>
                                    setRebuttalComments(e.target.value)
                                }
                            ></textarea>
                        </div>
                        <div className='alret_modal_bottom'>
                            <Button
                                variant='link'
                                className='bordered_btn'
                                onClick={handleCloseModal}
                            >
                                {t('Common.No')}
                            </Button>
                            <Button
                                variant='primary'
                                className='ms-3'
                                onClick={handleRebuttal}
                                disabled={rebuttalComment ? false : true}
                            >
                                {t('Common.Yes')}, {getNegativeAction(true)}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
