import React, { useEffect, useState } from 'react';
import { Accordion, Breadcrumb, } from 'react-bootstrap';
import 'react-dropdown-tree-select/dist/styles.css';
import { imagePath } from '../../CommonLogic';
import DIYSideNav from './DIYSideNav';
import './diy.css';
import EditableTreeOrg from './EditableTreeOrg';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import * as AuthSelector from '../../store/auth/selectors';
import { client } from '../../services/apiService';
import { useTranslation } from 'react-i18next';

const DIYOrganizationSetup = () => {
    const organization = useSelector(AuthSelector.organization);
    const obsOrg = useSelector(AuthSelector.obs);
    const forOmindPersona = organization?.organisationName == 'Omind';
    const [organizationOption, setOrganizationOption] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState({});
    const [obsForOrganization, setObsForOrganization] = useState(obsOrg);
    const [organigationName, setOrganigationName] = useState(organization?.organisationName);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const language = localStorage.getItem('selectedLanguage');
        i18n.changeLanguage(language);
    }, []);

    const getOrganization = async () => {
        const res = await client.get('/organisations');
        setOrganizationOption(res?.data);
        const organisationData = res?.data?.find(
            el =>
                el?.attributes?.Organisation_Name ===
                organization?.organisationName
        );
        setSelectedOrganization({
            value: organisationData?.id,
            label: organisationData?.attributes?.Organisation_Name,
        });
    };

    const getObs = async value => {
        const res = await client.get(`/organisations/obs/${value}`);
        setObsForOrganization(res?.data);
    };

    useEffect(() => {
        forOmindPersona && getOrganization();
    }, [forOmindPersona]);

    return (
        <>
            <div className='page-wrapper no_overflow_x page_loader--'>
                <div className='content pb-0'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-4 col-sm-12 sidebar_right_nav sidebar_right_style'>
                            <div className='sidebar-right'>
                                <p>{t('Organization Setup.Organization Setup')}</p>
                                <ul className='page_nav'>
                                    <DIYSideNav tab='org-setup' />
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-9 col-md-8 col-sm-12 content_wrap_main content_wrap_style'>
                            <div className='content_wrap_comman'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='breadcrumb_box'>
                                            <Breadcrumb>
                                                <Breadcrumb.Item
                                                    as='li'
                                                    href='/'>
                                                    <img
                                                        src={imagePath(
                                                            '/images/home_icon_pagig.svg'
                                                        )}
                                                    />
                                                </Breadcrumb.Item>
                                                <Breadcrumb.Item
                                                    as='li'
                                                    active
                                                    aria-current='page'>
                                                    {t('Organization Setup.Setup')} / {t('Organization Setup.Organization Setup')}
                                                </Breadcrumb.Item>
                                            </Breadcrumb>
                                        </div>
                                    </div>
                                </div>
                                <div className='two_column_title page_title_box'>
                                    <h3 className='page_title'>
                                        {t('Organization Setup.Organization Breakdown Structure')}

                                    </h3>

                                </div>
                                <div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-5'>
                                        <div className='mb-2'>
                                            {forOmindPersona &&
                                                <Select
                                                    options={organizationOption.map(des => ({
                                                        value: des.id,
                                                        label: des?.attributes
                                                            ?.Organisation_Name,
                                                    })
                                                    )}
                                                    defaultValue={selectedOrganization}
                                                    onChange={selectedOption => {
                                                        setSelectedOrganization({
                                                            label: selectedOption?.label,
                                                            value: selectedOption?.value
                                                        })
                                                        getObs(selectedOption?.value)
                                                        setOrganigationName(selectedOption?.label);
                                                    }}
                                                    value={selectedOrganization}
                                                    isSearchable
                                                    placeholder={t('Organization Setup.Search Organization')}
                                                />

                                            }
                                        </div>
                                        <div className='obs_user_creations '>
                                            <Accordion defaultActiveKey='0'>
                                                <Accordion.Item eventKey='0'>
                                                    <Accordion.Body>
                                                        <EditableTreeOrg obsForOrganization={obsForOrganization} forOmindPersona={forOmindPersona} organigationName={organigationName} selectedOrganization={selectedOrganization} getObs={getObs}/>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DIYOrganizationSetup;
