import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";

const LineChartComponent = ({ data = [], categories = [], xLabel = "", yLabel = "" }) => {
    const initialChartOptions = {
        chart: {
            type: 'line',
            height: 350,
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
        },
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        xaxis: {
            categories: [...categories],
            title: {
                text: xLabel,
                style: {
                    fontSize: '14px',
                    fontWeight: '500',
                },
            },
            labels: {
                rotate: -45,
                style: {
                    fontSize: "12px",
                },
            },
        },
        yaxis: {
            title: {
                text: yLabel,
                style: {
                    fontSize: '14px',
                    fontWeight: '500',
                    color: '#344054',
                },
            },
        },
        tooltip: {
            enabled: true,
        },
        colors: ['#072B15', '#0B4522', '#126E36', '#1DB056', '#23CE6B', '#27E376', '#2FFF8E', '#84FFBC'],
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 10,
            offsetY: 10,
        },
    };

    const [chartOptions, setChartOptions] = useState({});
    const [chartSeries, setChartSeries] = useState([]);

    useEffect(() => {
        const formattedData = data.map((agent) => {
            const paddedData = Array(categories.length).fill(null);
            agent.data.forEach((value, index) => {
                if (index < paddedData.length) {
                    paddedData[index] = parseFloat(value) || null;
                }
            });
            return {
                name: agent.name,
                data: paddedData,
            };
        });
        setChartSeries(formattedData);
        setChartOptions(initialChartOptions);
    }, [data, categories]);

    return (
        <div style={{ width: "100%", height: "350px" }}>
            <Chart options={chartOptions} series={chartSeries} type="line" height={350} />
        </div>
    );
};

export default LineChartComponent;
