import React, { useEffect, useState } from 'react';
import './table.css';
import { toolTipHOC } from '../../../../CommonLogic';

const TableWithFixedColumn = ({ headers, data, widths = [], tooltipEnabled = false, rowOnClick = () => { } }) => {
    const [sortedRows, setSortedRows] = useState(data);
    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: null,
    });

    useEffect(() => {
        const header = headers[0]
        const key = header;
        let direction = 'ascending';

        // Toggle sort direction
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }

        const sorted = [...data].sort((a, b) => {
            if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
            return 0;
        });

        setSortedRows(sorted);
        setSortConfig({ key, direction });
    }, [data, headers])

    const handleSort = header => {
        const key = header;
        let direction = 'ascending';

        // Toggle sort direction
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }

        const sorted = [...data].sort((a, b) => {
            if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
            return 0;
        });

        setSortedRows(sorted);
        setSortConfig({ key, direction });
    };

    return (
        <div className='table_style_comman '>
            <div className='table-responsive fixed_column_table '>
                <table class="table">
                    <thead>
                        <tr>
                            {headers.map((header, index) => (
                                <th
                                    key={index}
                                    style={{ width: widths[index] || 'auto', cursor: 'pointer' }}
                                    onClick={() => handleSort(header)}
                                >
                                    {toolTipHOC(header, header, tooltipEnabled, 'bottom', {class:'coa_tbl_ellipsis'})}
                                    <span
                                        className={`tbl_sorting_icon ${sortConfig.key === header ? (sortConfig.direction === 'ascending' ? 'ascending-icon' : 'descending-icon') : ''}`}
                                    ></span>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {sortedRows.map((row, rowIndex) => (
                            <tr key={rowIndex} onClick={() => rowOnClick(row?.['id'])}>
                                {headers.map((header, colIndex) => (
                                    <td key={colIndex}>
                                        {toolTipHOC(row[header], row[header], tooltipEnabled, 'left', {class:'coa_tbl_ellipsis'})}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TableWithFixedColumn;