const paraMeterOptionsDefault = [
    {
        label: 'Call Handling',
        value: 'Call Handling',
    },
    {
        label: 'CRM action',
        value: 'CRM action',
    },
    {
        label: 'Softskills',
        value: 'Softskills',
    },
    {
        label: 'Fatal parameter',
        value: 'Fatal parameter',
    },
    {
        label: 'Reward scores',
        value: 'Reward scores',
    },
];

const paraMeterOptionsDefaultInGerman = [
    {
        label: 'Anrufabwicklung', // Call Handling
        value: 'Call Handling',
    },
    {
        label: 'CRM-Aktion', // CRM action
        value: 'CRM action',
    },
    {
        label: 'Softskills', // Softskills
        value: 'Softskills',
    },
    {
        label: 'Fataler Parameter', // Fatal parameter
        value: 'Fatal parameter',
    },
    {
        label: 'Belohnungspunkte', // Reward scores
        value: 'Reward scores',
    },
];


const subParaMeterOptionsDefault = {
    'Call Handling': [
        {
            label: 'Preparation and Profile check on CRM',
            value: 'Preparation and Profile check on CRM',
            score: 5,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'Complete Introduction',
            value: 'Complete Introduction',
            score: 5,
            dropdown: 'Y/N/NA',
        },
        {
            label: "Profiling to understand the client's background",
            value: "Profiling to understand the client's background",
            score: 5,
            dropdown: 'Y/N/NA',
        },
        {
            label: "Proper explanation of product and offer, as per client's requirement",
            value: "Proper explanation of product and offer, as per client's requirement",
            score: 5,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'Skillfully driving the call towards deposit',
            value: 'Skillfully driving the call towards deposit',
            score: 5,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'Effective objection handling',
            value: 'Effective objection handling',
            score: 5,
            dropdown: 'Y/N/NA',
        },
        {
            label: "Proper probing if client doesn't show interest or raises any concern",
            value: "Proper probing if client doesn't show interest or raises any concern",
            score: 5,
            dropdown: 'Y/N/NA',
        },
        {
            label: "Building a two way communication to get client's engagement on call properly",
            value: "Building a two way communication to get client's engagement on call properly",
            score: 5,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'Urgency creation for deposit, if client asks for time',
            value: 'Urgency creation for deposit, if client asks for time',
            score: 5,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'Rebuttal used for higher amount of deposit, if client is looking to start with low',
            value: 'Rebuttal used for higher amount of deposit, if client is looking to start with low',
            score: 5,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'Proper guidance about payment process and modes',
            value: 'Proper guidance about payment process and modes',
            score: 5,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'Taking call back request (if required)',
            value: 'Taking call back request (if required)',
            score: 5,
            dropdown: 'Y/N/NA',
        },
    ],
    'CRM action': [
        {
            label: 'Call was tagged properly',
            value: 'Call was tagged properly',
            score: 4,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'Email was sent as per call scenario',
            value: 'Email was sent as per call scenario',
            score: 3,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'Call was rescheduled in CRM (if required)',
            value: 'Call was rescheduled in CRM (if required)',
            score: 3,
            dropdown: 'Y/N/NA',
        },
    ],
    Softskills: [
        {
            label: 'Attentiveness (inactive or silent on call)',
            value: 'Attentiveness (inactive or silent on call)',
            score: 5,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'Energetic and enthusiastic',
            value: 'Energetic and enthusiastic',
            score: 5,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'Clarity and proper pace of speech',
            value: 'Clarity and proper pace of speech',
            score: 5,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'Confident on call',
            value: 'Confident on call',
            score: 5,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'Polite and friendly',
            value: 'Polite and friendly',
            score: 5,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'No Interruption or Parallel talk',
            value: 'No Interruption or Parallel talk',
            score: 5,
            dropdown: 'Y/N/NA',
        },
    ],
    'Fatal parameter': [
        {
            label: 'Misleading client for deposit/account verification',
            value: 'Misleading client for deposit/account verification',
            score: 0,
            dropdown: 'Fatal/Not-fatal',
        },
        {
            label: "Asking for client's personal details (i.e account/payment mode details)",
            value: "Asking for client's personal details (i.e account/payment mode details)",
            score: 0,
            dropdown: 'Fatal/Not-fatal',
        },
        {
            label: 'Using sarcastic/commanding/impolite tone',
            value: 'Using sarcastic/commanding/impolite tone',
            score: 0,
            dropdown: 'Fatal/Not-fatal',
        },
        {
            label: 'Using profanity or having unprofessional discussion',
            value: 'Using profanity or having unprofessional discussion',
            score: 0,
            dropdown: 'Fatal/Not-fatal',
        },
    ],
    'Reward scores': [
        {
            label: "Client has verified the account on agent's request",
            value: "Client has verified the account on agent's request",
            score: 10,
            dropdown: 'Y/N/NA',
        },
        {
            label: "Client has deposited on agent's request",
            value: "Client has deposited on agent's request",
            score: 10,
            dropdown: 'Y/N/NA',
        },
        {
            label: "Client has deposited above KPI on agent's request",
            value: "Client has deposited above KPI on agent's request",
            score: 10,
            dropdown: 'Y/N/NA',
        },
    ],
};

const subParaMeterOptionsDefaultInGerman = {
    'Call Handling': [
        {
            label: 'Vorbereitung und Profilprüfung im CRM',
            value: 'Preparation and Profile check on CRM',
            score: 5,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'Vollständige Einführung',
            value: 'Complete Introduction',
            score: 5,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'Profilierung zur Hintergrundanalyse des Kunden',
            value: "Profiling to understand the client's background",
            score: 5,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'Angemessene Erklärung von Produkt und Angebot gemäß Kundenanforderungen',
            value: "Proper explanation of product and offer, as per client's requirement",
            score: 5,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'Geschicktes Lenken des Gesprächs in Richtung Einzahlung',
            value: 'Skillfully driving the call towards deposit',
            score: 5,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'Effektive Einwandbehandlung',
            value: 'Effective objection handling',
            score: 5,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'Angemessenes Nachfragen, wenn der Kunde kein Interesse zeigt oder Bedenken äußert',
            value: "Proper probing if client doesn't show interest or raises any concern",
            score: 5,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'Aufbau einer Zwei-Wege-Kommunikation zur Kundenbindung während des Gesprächs',
            value: "Building a two way communication to get client's engagement on call properly",
            score: 5,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'Dringlichkeit für Einzahlung schaffen, wenn der Kunde um Zeit bittet',
            value: 'Urgency creation for deposit, if client asks for time',
            score: 5,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'Einwand für höhere Einzahlung, wenn der Kunde mit wenig starten möchte',
            value: 'Rebuttal used for higher amount of deposit, if client is looking to start with low',
            score: 5,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'Angemessene Anleitung zum Zahlungsprozess und den Modi',
            value: 'Proper guidance about payment process and modes',
            score: 5,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'Annahme einer Rückrufanfrage (falls erforderlich)',
            value: 'Taking call back request (if required)',
            score: 5,
            dropdown: 'Y/N/NA',
        },
    ],
    'CRM action': [
        {
            label: 'Anruf wurde ordnungsgemäß markiert',
            value: 'Call was tagged properly',
            score: 4,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'E-Mail wurde gemäß der Gesprächssituation gesendet',
            value: 'Email was sent as per call scenario',
            score: 3,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'Anruf wurde im CRM (falls erforderlich) neu terminiert',
            value: 'Call was rescheduled in CRM (if required)',
            score: 3,
            dropdown: 'Y/N/NA',
        },
    ],
    Softskills: [
        {
            label: 'Aufmerksamkeit (inaktiv oder still im Gespräch)',
            value: 'Attentiveness (inactive or silent on call)',
            score: 5,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'Energisch und enthusiastisch',
            value: 'Energetic and enthusiastic',
            score: 5,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'Klarheit und angemessenes Sprechtempo',
            value: 'Clarity and proper pace of speech',
            score: 5,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'Selbstbewusst im Gespräch',
            value: 'Confident on call',
            score: 5,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'Höflich und freundlich',
            value: 'Polite and friendly',
            score: 5,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'Keine Unterbrechung oder Parallelgespräche',
            value: 'No Interruption or Parallel talk',
            score: 5,
            dropdown: 'Y/N/NA',
        },
    ],
    'Fatal parameter': [
        {
            label: 'Kunden in Bezug auf Einzahlung/Kontoverifizierung in die Irre führen',
            value: 'Misleading client for deposit/account verification',
            score: 0,
            dropdown: 'Fatal/Not-fatal',
        },
        {
            label: 'Fragen nach persönlichen Kundendaten (z. B. Konto-/Zahlungsmodedaten)',
            value: "Asking for client's personal details (i.e account/payment mode details)",
            score: 0,
            dropdown: 'Fatal/Not-fatal',
        },
        {
            label: 'Verwendung eines sarkastischen/kommandierenden/unhöflichen Tons',
            value: 'Using sarcastic/commanding/impolite tone',
            score: 0,
            dropdown: 'Fatal/Not-fatal',
        },
        {
            label: 'Verwendung von Obszönitäten oder unprofessionellen Diskussionen',
            value: 'Using profanity or having unprofessional discussion',
            score: 0,
            dropdown: 'Fatal/Not-fatal',
        },
    ],
    'Reward scores': [
        {
            label: 'Kunde hat das Konto auf Anfrage des Agenten verifiziert',
            value: "Client has verified the account on agent's request",
            score: 10,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'Kunde hat auf Anfrage des Agenten eingezahlt',
            value: "Client has deposited on agent's request",
            score: 10,
            dropdown: 'Y/N/NA',
        },
        {
            label: 'Kunde hat über KPI auf Anfrage des Agenten eingezahlt',
            value: "Client has deposited above KPI on agent's request",
            score: 10,
            dropdown: 'Y/N/NA',
        },
    ],
};

export { paraMeterOptionsDefault, subParaMeterOptionsDefault, paraMeterOptionsDefaultInGerman, subParaMeterOptionsDefaultInGerman };
