import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as AuthSelectors from '../../../src/store/auth/selectors';
import { useTranslation } from 'react-i18next';


const DIYSideNav = ({ tab = 'user-management' }) => {
    const permissions = useSelector(AuthSelectors.accessPermissions);
    const orgStrId = useSelector(AuthSelectors.orgStrId);
    const navigate = useNavigate();
    const [active, setActive] = useState((new URL(window.location.href)?.pathname?.split('/')?.[4]) == "role-permission" ? "user-management" : tab);
    const { t, i18n } = useTranslation();

    const handleNavLink = activeNav => {
        if (active == activeNav) {
            return;
        }
        setActive(activeNav);
        navigate(`/o/${orgStrId}/setup/${activeNav}`);
    };

    return (
        <>
           {permissions.some(item => item.resource === "Organization Setup") && <li>
                <a
                    className={
                        active == 'org-setup' ? 'active' : ''
                    }
                    href='#'
                    onClick={e => {
                        e.preventDefault();
                        handleNavLink('org-setup');
                    }}
                >
                    {t('LandingScreen.Organization Setup')}
                </a>
            </li>}
            {permissions.some(item => item.resource === "User Onboarding") && <li>
                <a
                    className={
                        active == 'user-management' ? 'active' : ''
                    }
                    href='#'
                    onClick={e => {
                        e.preventDefault();
                        handleNavLink('user-management');
                    }}
                >
                    {t('LandingScreen.User Onboarding')}
                </a>
            </li>}
        </>
    );
};

export default DIYSideNav;
